import { useEffect, useMemo, useRef, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { JSEncrypt } from "jsencrypt";
import useSWR from "swr";
import Loader from "components/Loader";
import useQueryString from "hooks/useQueryString";
import {
  acceptTbcGooglePayPayment,
  acceptUfcGooglePayPayment,
  bogTransaction,
  checkOrderStatus,
  commonServiceInstance,
  credoTransaction,
  eCommerceServiceInstance,
  getOBProviders,
  oBRequest,
  orderCityPay,
  orderCityPayStatus,
  paymentServiceInstance,
  postTransactionAction,
  standardTransaction,
  startApplePayment,
  startTbcApplePayment,
  startTbcGooglePayment,
  startUfcApplePayment,
  startUfcGooglePayment,
  tbcTransaction,
} from "api";
import TippingUser from "components/TippingUser";
import Select from "components/select/Select";
import SelectPaymentMethod, {
  bankOptions,
} from "components/modals/SelectPaymentMethod";
import Modal from "components/modals/TestModal";
import { Link, Navigate, useLocation } from "react-router-dom";
import CustomInput from "components/CustomInput";
import { useNavigate } from "react-router-dom";
import { Spinner } from "components/Spinner";
import PaymentFeedback from "components/PaymentFeedback";
import { MERCHANT_NAME, GPAY_MERCHANT_ID, BOG_URL } from "config";
import { acceptGooglePayPayment, startGooglePayPayment } from "./api";
import UserNotFound from "components/UserNotFound";
import {
  LAST_ACTIVE_ORDER,
  MERCHANT_PROFILE_URL,
  PRODUCT_URL,
  SYSTEM_STATUS_URL,
  TRANSACTION_STATUS,
  USER_PROFILE_URL,
} from "helpers/fetcher/fetcher.config";
import axios from "axios";
import paymentReducer from "./reducer/paymentReducer";
import {
  SET_AMOUNT,
  SET_APPLE_PAY_STATE,
  SET_GPAY_STATE,
  SET_INPUT_ERROR,
  SET_LOADING_PAYMENT,
  SET_PAYMENT_RESULT,
  SET_PAYMENT_TYPE,
  SET_PRODUCTS,
  SET_TOGGLE_INSTALLMENT_MODAL,
  SET_TOGGLE_PAYMENT_MODAL,
} from "./reducer/actions";
import {
  AVAILABLE_PAYMENT_TYPES,
  INITIAL_STATE,
  PAYMENT_SERVICE_STATE,
  QR_TYPE,
  baseCardPaymentMethod,
  baseRequest,
  cardPaymentMethod,
  cardPaymentMethodTbc,
  cardPaymentMethodUfc,
} from "./config";
import startApplePaySession from "features/apple/utils/startApplePaySession";
import fail from "../../assets/images/Fail2.svg";
import { isAndroid } from "helpers/deviceHelper/deviceHelper";
import PoweredByKeepz from "assets/PoweredByKeepz";
import { RATING_ALLOWED_STATUSES } from "constants";
import {
  convertBase64,
  decrypt,
  decryptAES,
  encrypt as encryptJs,
  encryptAES,
  generateIV,
  generateKey,
  parseBase64,
} from "utils/encryption";
import "../../index.css";
import ExclamIcon from "assets/icons/ExclamIcon";
import startApplePaySessionTbc from "features/apple/utils/startApplePaySessionTbc";
import startApplePaySessionUfc from "features/apple/utils/startApplePaySessionUfc";
import { isChrome, isSafari, isIOS } from "react-device-detect";
import EcommerceModal from "components/modals/EcommerceModal";

import GoogleChromeIcon from "assets/icons/GoogleChromeIcon";
import ProductSelect from "components/ProductSelect/ProductSelect";
import ProductList from "components/ProductSelect/ProductList";
import InstallmentModal from "components/Installment/InstallmentModal";

const MERCHANT_IDENTIFIER = "merchant.bog.keepz";
const MERCHANT_IDENTIFIER_TBC = "merchant.tbc.keepz";
const MERCHANT_IDENTIFIER_CREDO = "merchant.keepz.credo";

const BogPayment = () => {
  const [
    {
      amount,
      loadingPayment,
      gpayState,
      applePayState,
      inputError,
      paymentResult,
      showPaymentModal,
      paymentType,
      selectProducts,
      showInstallmentModal,
    },
    dispatch,
  ] = useReducer(paymentReducer, INITIAL_STATE);

  const [reqQrStatus, setReqQrStatus] = useState();
  const [description, setDescription] = useState("");
  const [descError, setDescError] = useState(false);
  const [acquiringType, setAcquiringType] = useState({});
  const [commisonAmount, setCommisionAmount] = useState();
  const [obProviderId, setObProviderId] = useState();
  const [ObProvider, setObProvider] = useState();
  const [isNonResident, setIsNonResident] = useState(false);

  const [personalNumberError, setPersonalNumberError] = useState(false);
  const formRef = useRef(null);
  const inputRef = useRef(null);
  const descriptionRef = useRef(null);
  const [formData, setFormData] = useState({
    payerName: "",
    payerPersonalNumber: "",
    additionalDescription: "",
  });
  const [openChromModal, setOpenChromeModal] = useState(false);
  const payerNameRef = useRef(null);

  const payerPersonalNumberRef = useRef(null);
  const additionalDescriptionRef = useRef(null);

  localStorage.removeItem("integratorOrderId");

  const { pathname, search } = useLocation();

  const navigate = useNavigate();

  const { parseQuery } = useQueryString();
  const {
    toUserId = "",
    status = "",
    transactionId = "",
    amount: requestedAmount = "",
    qrType = "DEFAULT",
    receiverId = "",
    receiverType = "USER",
    integratorId = "",
    integratorOrderId: integratorCheckOrderId,
    productId,
    currency: currnecyReq = "",
  } = parseQuery;
  const sanitizeUUID = (uuid) =>
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
      uuid
    )
      ? uuid
      : null;
  const receiverUserId = sanitizeUUID(toUserId || receiverId);
  localStorage.setItem("receiverUserId", receiverUserId);

  const { data: systemData } = useSWR(SYSTEM_STATUS_URL, async (url) => {
    const {
      data: { value: data },
    } = await commonServiceInstance.get(url);
    return data;
  });
  localStorage.setItem("url", pathname + search);

  const {
    data: {
      amount: requestedAmount1,
      currency: currencyProduct = "",
      currencyRate: currRateProduct,
    } = {},
  } = useSWR(
    !!productId
      ? `${PRODUCT_URL}?ownerId=${receiverUserId}&identifier=${process.env.REACT_APP_INTEGRATOR_ID}&productId=${productId}&ownerType=${receiverType}`
      : null,
    async (url) => {
      try {
        const {
          data: {
            value: { encryptedData },
          },
        } = await paymentServiceInstance.get(url);
        return decrypt(encryptedData);
      } catch ({ response: { data: err } }) {
        console.log(err);
        dispatch({
          type: SET_PAYMENT_RESULT,
          payload: {
            show: true,
            type: err?.statusCode,
            group: err?.exceptionGroup,
          },
        });
      }
    }
  );

  const {
    data: {
      integratorOrderId = integratorCheckOrderId,
      currency: currencyCheck = "",
    } = {},
    error: qrCheckError,
  } = useSWR(
    useMemo(() => {
      if (integratorId.length && qrType !== QR_TYPE.REQUEST) {
        const encrypt = new JSEncrypt();
        encrypt.setPublicKey(process.env.REACT_APP_PUBLIC_ENCRIPTION_KEY);
        const encryptedData = encodeURIComponent(
          encrypt.encrypt(
            JSON.stringify({
              integratorId: integratorId,
              receiverId: receiverUserId,
            })
          )
        );

        return `${LAST_ACTIVE_ORDER}?identifier=${process.env.REACT_APP_INTEGRATOR_ID}&encryptedData=${encryptedData}`;
      } else {
        return null;
      }
    }, [integratorId, qrType, receiverUserId]),
    async (url) => {
      const {
        data: {
          value: { encryptedData },
        },
      } = await eCommerceServiceInstance.get(url);
      const encrypt = new JSEncrypt();
      encrypt.setPrivateKey(process.env.REACT_APP_INTEGRATOR_SECRET);
      const decryptedData = JSON.parse(encrypt.decrypt(encryptedData));
      const { amount: orderedAmount } = decryptedData;
      dispatch({ type: SET_AMOUNT, payload: orderedAmount });
      return decryptedData;
    }
  );
  const {
    data: { value: { result: { status: transactionStatus } = {} } = {} } = {},
  } = useSWR(
    transactionId
      ? `${TRANSACTION_STATUS}?providerId=${transactionId}&fromMobile=false`
      : null
  );

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const gpayRef = useRef();

  const availablePaymentOptions = useMemo(
    () => [
      ...(acquiringType?.availableAcquiringTypes?.STANDARD
        ? [AVAILABLE_PAYMENT_TYPES.CARD]
        : []),
      ...(gpayState === PAYMENT_SERVICE_STATE.AVAILABLE &&
      acquiringType?.availableAcquiringTypes?.GOOGLE_PAY
        ? [AVAILABLE_PAYMENT_TYPES.GOOGLE_PAY]
        : []),
      ...(applePayState === PAYMENT_SERVICE_STATE.AVAILABLE &&
      acquiringType?.availableAcquiringTypes?.APPLE_PAY
        ? [AVAILABLE_PAYMENT_TYPES.APPLE_PAY]
        : []),
      ...(acquiringType?.availableAcquiringTypes?.CRYPTO
        ? [AVAILABLE_PAYMENT_TYPES.BINANCE_PAY]
        : []),
      ...(acquiringType?.availableAcquiringTypes?.OPEN_BANKING ? ["ob"] : []),
      ...(acquiringType?.availableAcquiringTypes?.installment?.CREDO_INSTALLMENT
        ? ["installment"]
        : []),
    ],
    [gpayState, applePayState, acquiringType]
  );

  const { data, error: { response: { data: userError } = {} } = {} } = useSWR(
    receiverType === "USER"
      ? `${USER_PROFILE_URL}/${receiverUserId}?identifier=${process.env.REACT_APP_INTEGRATOR_ID}`
      : null,
    async (url) => {
      const {
        data: {
          value: { encryptedData, aes, encryptedKeys },
        },
      } = await paymentServiceInstance.get(url);
      const encrypteSecretKey = decrypt(encryptedKeys, {
        shouldParse: false,
      });
      const [base64SecretKey, base64IV] = encrypteSecretKey.split(".");
      const secretKey = parseBase64(base64SecretKey);
      const iv = parseBase64(base64IV);
      const encrypted = decryptAES(encryptedData, secretKey, iv);
      return encrypted;
    }
  );

  const {
    data: merchant,
    error: { response: { data: merchantError } = {} } = {},
  } = useSWR(
    receiverType === "BRANCH"
      ? `${MERCHANT_PROFILE_URL}/${receiverUserId}?identifier=${process.env.REACT_APP_INTEGRATOR_ID}`
      : null,
    async (url) => {
      const {
        data: {
          value: { encryptedData, aes, encryptedKeys },
        },
      } = await paymentServiceInstance.get(url);
      const encrypteSecretKey = decrypt(encryptedKeys, {
        shouldParse: false,
      });
      const [base64SecretKey, base64IV] = encrypteSecretKey.split(".");
      const secretKey = parseBase64(base64SecretKey);
      const iv = parseBase64(base64IV);
      const encrypted = decryptAES(encryptedData, secretKey, iv);
      return encrypted;
    }
  );
  const { data: installmentProperties, error } = useSWR(
    `/api/external/credo/installment/properties?identifier=${process.env.REACT_APP_INTEGRATOR_ID}`,
    async (url) => {
      const {
        data: {
          value: { encryptedData, encryptedKeys },
        },
      } = await paymentServiceInstance.get(url);
      const encrypteSecretKey = decrypt(encryptedKeys, {
        shouldParse: false,
      });
      const [base64SecretKey, base64IV] = encrypteSecretKey.split(".");
      const secretKey = parseBase64(base64SecretKey);
      const iv = parseBase64(base64IV);
      const encrypted = decryptAES(encryptedData, secretKey, iv);
      return encrypted;
    }
  );

  console.log(installmentProperties, "installmentProperties");

  const { data: { value: { rate = "" } = {} } = {} } = useSWR(
    integratorOrderId || integratorCheckOrderId
      ? `/api/integrator/order/rate/encrypted?identifier=${process.env.REACT_APP_INTEGRATOR_ID}`
      : null,
    async (url) => {
      try {
        const data = {
          integratorId: integratorId,
          integratorOrderId: integratorOrderId || integratorCheckOrderId,
        };
        const iv = generateIV();
        const secretKey = generateKey();
        const ivBase64 = convertBase64(iv);
        const secretKeyBase64 = convertBase64(secretKey);
        const base64 = `${secretKeyBase64}.${ivBase64}`;
        const encryptedBase64 = encryptJs(base64, {
          stringify: false,
          shouldEncode: true,
        });
        const encrypteData = encryptAES(data, secretKey, iv, {
          shouldEncode: true,
        });
        const {
          data: {
            value: { encryptedData, aes, encryptedKeys },
          },
        } = await eCommerceServiceInstance.get(
          url +
            `&encryptedData=${encrypteData}&encryptedKeys=${encryptedBase64}`
        );
        const encrypteSecretKey = decrypt(encryptedKeys, {
          shouldParse: false,
        });
        const [base64SecretKey, base64IV] = encrypteSecretKey.split(".");
        const secretkey = parseBase64(base64SecretKey);
        const IV = parseBase64(base64IV);
        const encrypted = decryptAES(encryptedData, secretkey, IV);
        return encrypted;
      } catch (error) {
        console.log(error);
      }
    }
  );

  const isAmountInputDisabled =
    (merchant?.acquiringDetails?.acquiringDetailsType == "PRODUCT" &&
      qrType == "DEFAULT") ||
    data?.amountForDefaultQR ||
    merchant?.amountForDefaultQR ||
    qrType === QR_TYPE.CHECK ||
    qrType === QR_TYPE.REQUEST ||
    qrType === QR_TYPE.CUSTOM;

  const fixedAmount = useMemo(
    () =>
      amount
        ? Number(String(amount).replace(/,/g, ".")).toFixed(2)
        : requestedAmount ||
          requestedAmount1 ||
          data?.amountForDefaultQR ||
          merchant?.amountForDefaultQR,
    [amount, requestedAmount, requestedAmount1, data, merchant]
  );

  const { data: commisonUsers, error: commisonUserError } = useSWR(
    receiverType === "USER"
      ? `${USER_PROFILE_URL}/${receiverUserId}/commissions?identifier=${
          process.env.REACT_APP_INTEGRATOR_ID
        }${integratorId ? `&integratorId=${integratorId}` : ""}`
      : null,
    async (url) => {
      const {
        data: {
          value: { encryptedData, aes, encryptedKeys },
        },
      } = await paymentServiceInstance.get(url);
      const encrypteSecretKey = decrypt(encryptedKeys, {
        shouldParse: false,
      });
      const [base64SecretKey, base64IV] = encrypteSecretKey.split(".");
      const secretkey = parseBase64(base64SecretKey);
      const IV = parseBase64(base64IV);
      const encrypted = decryptAES(encryptedData, secretkey, IV);
      return encrypted;
    }
  );

  const { data: commisonMerchant, error: commisonMerhcantError } = useSWR(
    receiverType === "BRANCH"
      ? `${MERCHANT_PROFILE_URL}/${receiverUserId}/commission?identifier=${
          process.env.REACT_APP_INTEGRATOR_ID
        }${integratorId ? `&integratorId=${integratorId}` : ""}`
      : null,
    async (url) => {
      const {
        data: {
          value: { encryptedData, aes, encryptedKeys },
        },
      } = await paymentServiceInstance.get(url);
      const encrypteSecretKey = decrypt(encryptedKeys, {
        shouldParse: false,
      });
      const [base64SecretKey, base64IV] = encrypteSecretKey.split(".");
      const secretkey = parseBase64(base64SecretKey);
      const IV = parseBase64(base64IV);
      const encrypted = decryptAES(encryptedData, secretkey, IV);
      return encrypted;
    }
  );

  const rangedAppleCommission =
    commisonUsers?.filter((item) => item.acquiringType == "APPLE_PAY") ||
    commisonMerchant?.filter((item) => item.acquiringType === "APPLE_PAY");

  const senderCommissionPercentApple = rangedAppleCommission?.find(
    (item) => item.startAmount <= amount
  );

  const rangedGoogleCommission =
    commisonUsers?.filter((item) => item.acquiringType == "GOOGLE_PAY") ||
    commisonMerchant?.filter((item) => item.acquiringType === "GOOGLE_PAY");

  const senderCommissionPercentGoogle = rangedGoogleCommission?.find(
    (item) => item.startAmount <= amount
  );

  const rangedStandardCommission =
    commisonUsers?.filter((item) => item.acquiringType == "STANDARD") ||
    commisonMerchant?.filter((item) => item.acquiringType === "STANDARD");

  const senderCommissionPercentStandard = rangedStandardCommission?.find(
    (item) => item.startAmount <= amount
  );

  const rangedCryptoCommission =
    commisonUsers?.filter((item) => item.acquiringType == "CRYPTO") ||
    commisonMerchant?.filter((item) => item.acquiringType === "CRYPTO");

  const senderCommissionPercentCrypto = rangedCryptoCommission?.find(
    (item) => item.startAmount <= amount
  );

  const rangedOBCommission =
    commisonUsers?.filter((item) => item.acquiringType == "OB") ||
    commisonMerchant?.filter((item) => item.acquiringType === "OB");

  const senderCommissionPercentOB = rangedOBCommission?.find(
    (item) => item.startAmount <= amount
  );

  let minValDB;
  let maxValDB;

  if (paymentType == "card") {
    minValDB = senderCommissionPercentStandard?.minAmountPerTransaction || 0.1;
    maxValDB =
      senderCommissionPercentStandard?.maxAmountPerTransaction || 999.99;
  } else if (paymentType == "apple") {
    minValDB = senderCommissionPercentApple?.minAmountPerTransaction || 0.1;
    maxValDB = senderCommissionPercentApple?.maxAmountPerTransaction || 999.99;
  } else if (paymentType == "gpay") {
    minValDB = senderCommissionPercentGoogle?.minAmountPerTransaction || 0.1;
    maxValDB = senderCommissionPercentGoogle?.maxAmountPerTransaction || 999.99;
  } else if (paymentType == "binance") {
    minValDB = senderCommissionPercentCrypto?.minAmountPerTransaction || 0.1;
    maxValDB = senderCommissionPercentCrypto?.maxAmountPerTransaction || 999.99;
  } else if (paymentType.includes("ob")) {
    minValDB = senderCommissionPercentOB?.minAmountPerTransaction || 0.1;
    maxValDB = senderCommissionPercentOB?.maxAmountPerTransaction || 999.99;
  }

  const handleInputChange = (e) => {
    const inputMax = 999999.99;
    const val = e.target.value.trim();

    const originalValue = val.startsWith("₾") ? val.slice(1, val.length) : val;
    let value = originalValue.replace(/[^0-9.,]*/g, "");
    value = value.replace(/\.{2,}/g, ".");
    value = value.replace(/\.,/g, ",");
    value = value.replace(/\,\./g, ",");
    value = value.replace(/\,{2,}/g, ",");
    value = value.replace(/\.[0-9]+\./g, ".");
    value = value.replace(/,/g, ".");

    if (
      value === "." ||
      (val.replaceAll(",", ".").match(/\./g) || []).length > 1 ||
      value.length > String(inputMax).length
    ) {
      return;
    }
    if (value.includes(".")) {
      const secondPortion = value.split(".")[1] || "";
      value =
        secondPortion.length > 2
          ? value.toString().slice(0, value.length - (secondPortion.length - 2))
          : value;
    }
    dispatch({
      type: SET_INPUT_ERROR,
      payload: Number(value) < minValDB || Number(value) > maxValDB,
    });
    if (val === "₾" || !val.length) {
      dispatch({ type: SET_AMOUNT, payload: "" });
      return;
    }
    if (Number(value) > inputMax) {
      return;
    }
    dispatch({ type: SET_AMOUNT, payload: value });
  };

  const changePaymentType = (type) => {
    dispatch({ type: SET_PAYMENT_TYPE, payload: type });
    dispatch({ type: SET_TOGGLE_PAYMENT_MODAL });
  };

  const handlePaymentResulClose = () => {
    if (status || transactionId) {
      navigate({
        pathname,
        search: window.location.search.replace("&status=FAIL", ""),
      });
    }
    dispatch({
      type: SET_PAYMENT_RESULT,
      payload: {
        show: false,
      },
    });
  };
  useEffect(() => {
    localStorage.removeItem("binance");
  }, []);

  useEffect(() => {
    const handlePopState = async () => {
      if (!localStorage.getItem("binance")) {
        return;
      }

      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(process.env.REACT_APP_PUBLIC_ENCRIPTION_KEY);
      const payment_url = localStorage.getItem("binance");
      const payload = {
        externalId: payment_url,
      };
      const encryptedData = encodeURIComponent(
        encrypt.encrypt(JSON.stringify(payload))
      );
      const { encryptedData: res } = await orderCityPayStatus(
        `encryptedData=${encryptedData}&identifier=${process.env.REACT_APP_INTEGRATOR_ID}`
      );
      encrypt.setPrivateKey(process.env.REACT_APP_INTEGRATOR_SECRET);
      const { status, id, genericId } = JSON.parse(encrypt.decrypt(res));
      if (status) {
        if (RATING_ALLOWED_STATUSES.includes(status)) {
          const redirectURL = `/success?acquiringTransactionId=${id}&amount=${fixedAmount}&receiverName=${encodeURIComponent(
            (merchant || data)?.name
          )}&ratingEnabled=${!!(
            merchant?.reviewRequired || data?.reviewRequired
          )}&genericId=${genericId || ""}`;
          navigate(redirectURL);
        } else {
          dispatch({
            type: SET_PAYMENT_RESULT,
            payload: {
              show: true,
            },
          });
        }
      }
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
      localStorage.removeItem("binance");
    };
  }, [merchant, data]);

  const standardPriorityArray =
    acquiringType?.availableAcquiringTypes?.STANDARD?.sort(
      (a, b) => a.priority - b.priority
    );

  const applePriorityArray =
    acquiringType?.availableAcquiringTypes?.APPLE_PAY?.sort(
      (a, b) => a.priority - b.priority
    );

  const googlePayPriorityArray =
    acquiringType?.availableAcquiringTypes?.GOOGLE_PAY?.sort(
      (a, b) => a.priority - b.priority
    );

  const curr =
    selectProducts?.currency ||
    currencyCheck ||
    currencyProduct ||
    currnecyReq ||
    (data || merchant)?.currency ||
    "GEL";

  const currRate =
    selectProducts?.currencyRate ||
    rate ||
    currRateProduct ||
    (data || merchant)?.currencyRate ||
    0;

  function convertToEur(amount) {
    return Math.ceil((Number(amount) * currRate * 100).toFixed(2)) / 100;
  }

  const handlePayment = async (e) => {
    e.preventDefault();
    if (orderNotFound) {
      window.location.reload();
    }

    if (!navigator.onLine) {
      dispatch({
        type: SET_PAYMENT_RESULT,
        payload: {
          show: true,
          type: "INTERNET",
        },
      });
      return;
    }
    setDescError(false);
    setPersonalNumberError(false);
    if (
      (data || merchant)?.descriptionRequired &&
      !description.length &&
      descriptionRef?.current
    ) {
      setDescError(true);
      return descriptionRef.current.focus();
    }
    if (
      merchant?.payerNameRequired &&
      !formData.payerName.length &&
      payerNameRef?.current
    ) {
      return payerNameRef.current.focus();
    }
    if (
      merchant?.personalNumberRequired &&
      !formData.payerPersonalNumber.length &&
      payerPersonalNumberRef?.current
    ) {
      return payerPersonalNumberRef.current.focus();
    }
    if (
      merchant?.purposeRequired &&
      !formData.additionalDescription.length &&
      additionalDescriptionRef?.current
    ) {
      return additionalDescriptionRef.current.focus();
    }
    if (
      merchant?.personalNumberRequired &&
      formData.payerPersonalNumber.length != 11
    ) {
      setPersonalNumberError(true);
      return;
    }
    if (
      merchant?.personalNumberOrPassportRequired &&
      !formData.payerPersonalNumber.length &&
      payerPersonalNumberRef?.current
    ) {
      return payerPersonalNumberRef.current.focus();
    }

    const lang = language === "ge" ? "KA" : "EN";
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(process.env.REACT_APP_PUBLIC_ENCRIPTION_KEY);

    dispatch({ type: SET_LOADING_PAYMENT, payload: true });

    if (paymentType === AVAILABLE_PAYMENT_TYPES.GOOGLE_PAY) {
      const payload = {
        convertedAmount:
          curr == "EUR" || curr == "USD"
            ? convertToEur(fixedAmount)
            : Number(fixedAmount),
        amount: Number(fixedAmount),
        receiverType: receiverType || "User",
        ...(totalWithCommision?.comissionGoogle && {
          senderCommissionAmount: totalWithCommision?.comissionGoogle,
        }),
        ...(integratorId && { integratorId }),
        ...((integratorOrderId || productId) && {
          integratorOrderId: integratorOrderId || productId,
        }),
        ...(!!description.length && { description }),
        ...(!!formData.payerName.length && { payerName: formData.payerName }),
        ...(!!formData.additionalDescription.length && {
          purpose: formData.additionalDescription,
        }),
        ...(!!formData.payerPersonalNumber.length && {
          personalNumber: formData.payerPersonalNumber,
        }),
        qrType: qrType,
        currency: curr || "GEL",
        fromMobile: false,
        receiverId: receiverUserId,
        lang,
      };
      localStorage.setItem("paymentMethod", "googlePay");
      if (googlePayPriorityArray[0]?.acquiringName == "BOG") {
        localStorage.setItem("acquiring", "bog");
        const paymentDataRequest = Object.assign({}, baseRequest);
        paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod];
        paymentDataRequest.transactionInfo = {
          totalPriceStatus: "FINAL",
          totalPrice:
            curr == "EUR" || curr == "USD"
              ? convertToEur(fixedAmount).toString()
              : fixedAmount,
          currencyCode: "GEL",
          countryCode: "GE",
        };
        paymentDataRequest.merchantInfo = {
          merchantName: MERCHANT_NAME,
          merchantId: GPAY_MERCHANT_ID,
        };

        const result = encrypt.encrypt(JSON.stringify(payload));
        let googlePayResponse;
        try {
          googlePayResponse = await startGooglePayPayment({
            encryptedData: result,
            identifier: process.env.REACT_APP_INTEGRATOR_ID,
          });
        } catch ({ response: { data: err } }) {
          console.log(err);
          dispatch({
            type: SET_PAYMENT_RESULT,
            payload: {
              show: true,
              type: err?.statusCode,
              group: err?.exceptionGroup,
            },
          });
          dispatch({ type: SET_LOADING_PAYMENT, payload: false });
          return;
        }

        gpayRef.current
          .loadPaymentData(paymentDataRequest)
          .then(async (paymentData) => {
            try {
              const token =
                paymentData.paymentMethodData.tokenizationData.token;
              const cardNetwork =
                paymentData.paymentMethodData.info.cardNetwork;
              const {
                genericId,
                sessionId,
                params: { transaction_id: merchantTransactionId } = {},
              } = googlePayResponse;

              const {
                state,
                url = "",
                result: { status = "" } = {},
                versions,
              } = await acceptGooglePayPayment(token, {
                sessionId,
                merchantTransactionId,
                cardBrand: cardNetwork,
              });

              if (status === "SUCCESS") {
                const redirectURL = `/success?acquiringTransactionId=${merchantTransactionId}&receiverType=${receiverType}&amount=${fixedAmount}&receiverName=${encodeURIComponent(
                  (merchant || data)?.name
                )}&ratingEnabled=${!!(
                  merchant?.reviewRequired || data?.reviewRequired
                )}&tipsEnabled=${!!(
                  merchant?.tipsEnabled || data?.tipsEnabled
                )}&currency=${
                  curr || "GEL"
                }&receiverId=${receiverUserId}&paymentProviderEnum=BOG&paymentMethod=google&currencyRate=${
                  curr == "EUR" || curr == "USD" ? currRate : "0"
                }&genericId=${genericId}`;
                navigate(redirectURL);
                return;
              } else if (state === "in_progress") {
                navigate(
                  `/pending?token=${sessionId}&acquiringTransactionId=${merchantTransactionId}&receiverType=${receiverType}&amount=${fixedAmount}&receiverName=${encodeURIComponent(
                    (merchant || data)?.name
                  )}&ratingEnabled=${!!(
                    merchant?.reviewRequired || data?.reviewRequired
                  )}&tipsEnabled=${!!(
                    merchant?.tipsEnabled || data?.tipsEnabled
                  )}&receiverId=${receiverUserId}`
                );
              } else if (state === "3ds2_prepare") {
                const sortedVersions = versions.sort((a, b) => {
                  const aParts = a.split(".").map(Number);
                  const bParts = b.split(".").map(Number);

                  for (let i = 0; i < aParts.length; i++) {
                    if (aParts[i] > bParts[i]) return -1;
                    if (aParts[i] < bParts[i]) return 1;
                  }
                  return 0;
                });
                localStorage.setItem("version", sortedVersions[0]);

                const options = {
                  method: "POST",
                  url: `${BOG_URL}/payment/${sessionId}/3ds2-prepare/accept`,
                  params: {
                    version: sortedVersions[0],
                    "device.channel": "BRW",
                    ...(sortedVersions[0] === "2.1.0" && {
                      "device.browserAcceptHeader":
                        "application/json, text/javascript, /; q=0.01",
                    }),
                    "device.browserIP": "127.0.0.1",
                    "device.browserLanguage":
                      sortedVersions[0] === "2.1.0" ? "en-US" : "EN",
                    ...(sortedVersions[0] === "2.1.0" && {
                      "device.browserJavaEnabled": false,
                    }),
                    "device.browserColorDepth":
                      sortedVersions[0] === "2.2.0" ? "32" : "24",
                    "device.browserScreenHeight": "800",
                    "device.browserScreenWidth": "480",
                    "device.browserTZ": "180",
                    "device.browserUserAgent":
                      sortedVersions[0] === "2.1.0"
                        ? "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Mobile Safari/537.36"
                        : "Gecko",
                    ...(sortedVersions[0] === "2.2.0" && {
                      "device.browserJavascriptEnabled": false,
                    }),
                    challengeWindowSize:
                      sortedVersions[0] === "2.2.0" ? "02" : "03",
                    // iframeReturnUrl,
                  },
                };

                const {
                  data: { url, post = {} },
                } = await axios.request(options);

                if (!url) {
                  throw new Error("Something went wrong");
                }

                formRef.current.action = url;
                if ("threeDSMethodData" in post) {
                  localStorage.setItem("sessionId", sessionId);
                  inputRef.current.name = "threeDSMethodData";
                  inputRef.current.value = post.threeDSMethodData;
                } else {
                  inputRef.current.name = "creq";
                  inputRef.current.value = post.creq;
                }

                formRef.current.submit();
              } else if (state === "redirect") {
                window.open(url, "_self");
              } else {
                throw new Error("Unknow state");
              }
            } catch ({ response: { data: err } }) {
              console.log(err);
              if (integratorCheckOrderId || integratorOrderId) {
                const { redirectRequired, failRedirectUri } =
                  await postTransactionAction(integratorOrderId);
                if (redirectRequired && failRedirectUri) {
                  window.location.href = failRedirectUri;
                  return;
                }
              }
              dispatch({ type: SET_LOADING_PAYMENT, payload: false });
              dispatch({
                type: SET_PAYMENT_RESULT,
                payload: {
                  show: true,
                  type: err?.statusCode,
                  group: err?.exceptionGroup,
                },
              });
            }
          })
          .catch(() => {
            setTimeout(() => {
              dispatch({ type: SET_LOADING_PAYMENT, payload: false });
            }, 3000);
          });
      } else if (googlePayPriorityArray[0]?.acquiringName == "TBC") {
        localStorage.setItem("acquiring", "tbc");
        const paymentDataRequest = Object.assign({}, baseRequest);
        paymentDataRequest.allowedPaymentMethods = [cardPaymentMethodTbc];
        paymentDataRequest.transactionInfo = {
          totalPriceStatus: "FINAL",
          totalPrice:
            curr == "EUR" || curr == "USD"
              ? convertToEur(fixedAmount).toString()
              : fixedAmount,
          currencyCode: "GEL",
          countryCode: "GE",
        };
        paymentDataRequest.merchantInfo = {
          merchantName: "keepz",
          merchantId: "BCR2DN4TWWKZ77LS",
        };

        let googlePayResponse;
        try {
          googlePayResponse = await startTbcGooglePayment(payload);
        } catch ({ response: { data: err } }) {
          console.log(err);
          dispatch({
            type: SET_PAYMENT_RESULT,
            payload: {
              show: true,
              type: err?.statusCode,
              group: err?.exceptionGroup,
            },
          });
          dispatch({ type: SET_LOADING_PAYMENT, payload: false });
          return;
        }

        gpayRef.current
          .loadPaymentData(paymentDataRequest)
          .then(async (paymentData) => {
            try {
              const token =
                paymentData.paymentMethodData.tokenizationData.token;
              const cardNetwork =
                paymentData.paymentMethodData.info.cardNetwork;
              const { genericId, transactionId: merchantTransactionId } =
                googlePayResponse;

              const { data: dataGoogle } = await acceptTbcGooglePayPayment(
                {
                  payment_method: "google_pay",
                  google_pay: {
                    card_network: cardNetwork,
                    token: token,
                  },
                },
                merchantTransactionId
              );

              if (dataGoogle?.attributes?.status === "FINISHED") {
                const redirectURL = `/success?acquiringTransactionId=${merchantTransactionId}&receiverType=${receiverType}&amount=${fixedAmount}&receiverName=${encodeURIComponent(
                  (merchant || data)?.name
                )}&ratingEnabled=${!!(
                  merchant?.reviewRequired || data?.reviewRequired
                )}&tipsEnabled=${!!(
                  merchant?.tipsEnabled || data?.tipsEnabled
                )}&currency=${
                  curr || "GEL"
                }&receiverId=${receiverUserId}&paymentProviderEnum=TBC&paymentMethod=google&currencyRate=${
                  curr == "EUR" || curr == "USD" ? currRate : "0"
                }&genericId=${genericId}`;
                navigate(redirectURL);
                return;
              } else if (dataGoogle?.attributes?.status === "in_progress") {
                console.log("in progress");
              } else if (dataGoogle?.attributes?.status === "3ds2_prepare") {
                console.log("3ds state");
              } else if (dataGoogle?.attributes?.status === "redirect") {
                window.open(dataGoogle?.attributes?.status, "_self");
              } else {
                throw new Error("Unknow state");
              }
            } catch ({ response: { data: err } }) {
              if (integratorCheckOrderId || integratorOrderId) {
                const { redirectRequired, failRedirectUri } =
                  await postTransactionAction(integratorOrderId);
                if (redirectRequired && failRedirectUri) {
                  window.location.href = failRedirectUri;
                  return;
                }
              }
              dispatch({ type: SET_LOADING_PAYMENT, payload: false });
              console.log(err);
              dispatch({
                type: SET_PAYMENT_RESULT,
                payload: {
                  show: true,
                  type: err?.statusCode,
                  group: err?.exceptionGroup,
                },
              });
            }
          })
          .catch((err) => {
            setTimeout(() => {
              dispatch({ type: SET_LOADING_PAYMENT, payload: false });
            }, 3000);
          });
      } else if (googlePayPriorityArray[0]?.acquiringName == "CREDO") {
        localStorage.setItem("acquiring", "tbc");
        const paymentDataRequest = Object.assign({}, baseRequest);
        paymentDataRequest.allowedPaymentMethods = [cardPaymentMethodUfc];
        paymentDataRequest.transactionInfo = {
          totalPriceStatus: "FINAL",
          totalPrice:
            curr == "EUR" || curr == "USD"
              ? convertToEur(fixedAmount).toString()
              : fixedAmount,
          currencyCode: "GEL",
          countryCode: "GE",
        };
        paymentDataRequest.merchantInfo = {
          merchantName: "keepz",
          merchantId: "BCR2DN4TXXE6LEZ5",
        };

        let googlePayResponse;
        try {
          googlePayResponse = await startUfcGooglePayment(payload);
        } catch ({ response: { data: err } }) {
          dispatch({
            type: SET_PAYMENT_RESULT,
            payload: {
              show: true,
              type: err?.statusCode,
              group: err?.exceptionGroup,
            },
          });
          dispatch({ type: SET_LOADING_PAYMENT, payload: false });
          return;
        }

        gpayRef.current
          .loadPaymentData(paymentDataRequest)
          .then(async (paymentData) => {
            try {
              const token =
                paymentData.paymentMethodData.tokenizationData.token;
              const cardNetwork =
                paymentData.paymentMethodData.info.cardNetwork;
              const { genericId, transactionId: merchantTransactionId } =
                googlePayResponse;

              const { data: dataGoogle } = await acceptUfcGooglePayPayment(
                {
                  payment_method: "google_pay",
                  google_pay: {
                    card_network: cardNetwork,
                    token: token,
                  },
                },
                merchantTransactionId
              );

              if (dataGoogle?.attributes?.status === "FINISHED") {
                const redirectURL = `/success?acquiringTransactionId=${merchantTransactionId}&receiverType=${receiverType}&amount=${fixedAmount}&receiverName=${encodeURIComponent(
                  (merchant || data)?.name
                )}&ratingEnabled=${!!(
                  merchant?.reviewRequired || data?.reviewRequired
                )}&tipsEnabled=${!!(
                  merchant?.tipsEnabled || data?.tipsEnabled
                )}&currency=${
                  curr || "GEL"
                }&receiverId=${receiverUserId}&paymentProviderEnum=CREDO&paymentMethod=google&currencyRate=${
                  curr == "EUR" || curr == "USD" ? currRate : "0"
                }&genericId=${genericId}`;
                navigate(redirectURL);
                return;
              } else if (dataGoogle?.attributes?.status === "in_progress") {
                console.log("in progress");
              } else if (dataGoogle?.attributes?.status === "3ds2_prepare") {
                console.log("3ds state");
              } else if (dataGoogle?.attributes?.status === "redirect") {
                window.open(dataGoogle?.attributes?.status, "_self");
              } else {
                throw new Error("Unknow state");
              }
            } catch ({ response: { data: err } }) {
              if (integratorCheckOrderId || integratorOrderId) {
                const { redirectRequired, failRedirectUri } =
                  await postTransactionAction(integratorOrderId);
                if (redirectRequired && failRedirectUri) {
                  window.location.href = failRedirectUri;
                  return;
                }
              }
              dispatch({ type: SET_LOADING_PAYMENT, payload: false });
              console.log(err);
              dispatch({
                type: SET_PAYMENT_RESULT,
                payload: {
                  show: true,
                  type: err?.statusCode,
                  group: err?.exceptionGroup,
                },
              });
            }
          })
          .catch((err) => {
            setTimeout(() => {
              dispatch({ type: SET_LOADING_PAYMENT, payload: false });
            }, 3000);
          });
      }
    } else if (paymentType === AVAILABLE_PAYMENT_TYPES.APPLE_PAY) {
      localStorage.setItem("paymentMethod", "applePay");
      const startApplePayload = {
        amount: Number(fixedAmount),
        convertedAmount:
          curr == "EUR" || curr == "USD"
            ? convertToEur(fixedAmount)
            : Number(fixedAmount),
        receiverType: receiverType || "User",
        ...(totalWithCommision?.comissionApple && {
          senderCommissionAmount: totalWithCommision?.comissionApple,
        }),
        ...(integratorId && { integratorId }),
        ...((integratorOrderId || productId) && {
          integratorOrderId: integratorOrderId || productId,
        }),
        ...(!!description.length && { description }),
        ...(!!formData.payerName.length && { payerName: formData.payerName }),
        ...(!!formData.additionalDescription.length && {
          purpose: formData.additionalDescription,
        }),
        ...(!!formData.payerPersonalNumber.length && {
          personalNumber: formData.payerPersonalNumber,
        }),
        qrType: qrType,
        currency: curr || "GEL",
        fromMobile: false,
        receiverId: receiverUserId,
        lang,
      };
      if (applePriorityArray[0]?.acquiringName == "BOG") {
        localStorage.setItem("acquiring", "bog");
        try {
          const result = encrypt.encrypt(JSON.stringify(startApplePayload));
          const transactionIdPromise = startApplePayment({
            encryptedData: result,
            identifier: process.env.REACT_APP_INTEGRATOR_ID,
          });
          startApplePaySession(
            {
              amount:
                curr == "EUR" || curr == "USD"
                  ? totalWithCommision?.totalApple
                    ? convertToEur(Number(fixedAmount)) +
                      convertToEur(Number(totalWithCommision?.comissionApple))
                    : convertToEur(Number(fixedAmount))
                  : Number(totalWithCommision?.totalApple || fixedAmount),

              transactionIdPromise: transactionIdPromise,
              receiverType: receiverType,
              receiverName: (merchant || data)?.name,
              ratingEnabled: !!(
                merchant?.reviewRequired || data?.reviewRequired
              ),
              tipsEnabled: !!(merchant?.tipsEnabled || data?.tipsEnabled),
              receiverId: receiverUserId,
              navigate: navigate,
            },
            (res, genericId) => {
              if (res?.status === 0) {
                const url = `/success?acquiringTransactionId=${
                  res.transactionId
                }&amount=${fixedAmount}&receiverType=${receiverType}&receiverName=${
                  (merchant || data)?.name
                }&ratingEnabled=${!!(
                  merchant?.reviewRequired || data?.reviewRequired
                )}&tipsEnabled=${!!(
                  merchant?.tipsEnabled || data?.tipsEnabled
                )}&receiverId=${receiverUserId}&paymentProviderEnum=BOG&paymentMethod=apple&currencyRate=${
                  curr == "EUR" || curr == "USD" ? currRate : "0"
                }&currency=${curr || "GEL"}&genericId=${genericId}`;
                navigate(url);
              } else {
                dispatch({ type: SET_LOADING_PAYMENT, payload: false });
                async function failRedirct() {
                  if (integratorCheckOrderId || integratorOrderId) {
                    const { redirectRequired, failRedirectUri } =
                      await postTransactionAction(integratorOrderId);
                    if (redirectRequired && failRedirectUri) {
                      window.location.href = failRedirectUri;
                      return;
                    }
                  }
                }
                failRedirct();
                dispatch({
                  type: SET_PAYMENT_RESULT,
                  payload: {
                    show: true,
                    type: res?.response?.data?.statusCode,
                    group: res?.response?.data?.exceptionGroup,
                  },
                });
              }
            }
          );
        } catch (error) {
          async function failRedirct() {
            if (integratorCheckOrderId || integratorOrderId) {
              const { redirectRequired, failRedirectUri } =
                await postTransactionAction(integratorOrderId);
              if (redirectRequired && failRedirectUri) {
                window.location.href = failRedirectUri;
                return;
              }
            }
          }
          failRedirct();
          dispatch({
            type: SET_PAYMENT_RESULT,
            payload: {
              show: true,
              type: error?.response?.data?.statusCode,
              group: error?.response?.data?.exceptionGroup,
            },
          });
        }
      } else if (applePriorityArray[0]?.acquiringName == "TBC") {
        localStorage.setItem("acquiring", "tbc");
        try {
          const transactionIdPromise = startTbcApplePayment(startApplePayload);
          startApplePaySessionTbc(
            {
              amount:
                curr == "EUR" || curr == "USD"
                  ? totalWithCommision?.totalApple
                    ? convertToEur(Number(fixedAmount)) +
                      convertToEur(Number(totalWithCommision?.comissionApple))
                    : convertToEur(Number(fixedAmount))
                  : Number(totalWithCommision?.totalApple || fixedAmount),
              transactionIdPromise: transactionIdPromise,
            },
            (res, genericId) => {
              if (res?.status === 0) {
                const url = `/success?acquiringTransactionId=${
                  res.transactionId
                }&amount=${fixedAmount}&receiverType=${receiverType}&receiverName=${encodeURIComponent(
                  (merchant || data)?.name
                )}&ratingEnabled=${!!(
                  merchant?.reviewRequired || data?.reviewRequired
                )}&tipsEnabled=${!!(
                  merchant?.tipsEnabled || data?.tipsEnabled
                )}&receiverId=${receiverUserId}&paymentProviderEnum=TBC&paymentMethod=apple&currencyRate=${
                  curr == "EUR" || curr == "GEL" ? currRate : "0"
                }&currency=${curr || "GEL"}&genericId=${genericId}`;
                navigate(url);
              } else {
                dispatch({ type: SET_LOADING_PAYMENT, payload: false });
                async function failRedirct() {
                  if (integratorCheckOrderId || integratorOrderId) {
                    const { redirectRequired, failRedirectUri } =
                      await postTransactionAction(integratorOrderId);
                    if (redirectRequired && failRedirectUri) {
                      window.location.href = failRedirectUri;
                      return;
                    }
                  }
                }
                failRedirct();
                dispatch({
                  type: SET_PAYMENT_RESULT,
                  payload: {
                    show: true,
                    type: res?.response?.data?.statusCode,
                    group: res?.response?.data?.exceptionGroup,
                  },
                });
              }
            }
          );
        } catch (err) {
          async function failRedirct() {
            if (integratorCheckOrderId || integratorOrderId) {
              const { redirectRequired, failRedirectUri } =
                await postTransactionAction(integratorOrderId);
              if (redirectRequired && failRedirectUri) {
                window.location.href = failRedirectUri;
                return;
              }
            }
          }
          failRedirct();
          dispatch({
            type: SET_PAYMENT_RESULT,
            payload: {
              show: true,
              type: err?.response?.data?.statusCode,
              group: err?.response?.data?.exceptionGroup,
            },
          });
        }
      } else if (applePriorityArray[0]?.acquiringName == "CREDO") {
        try {
          const transactionIdPromise = startUfcApplePayment(startApplePayload);
          startApplePaySessionUfc(
            {
              amount:
                curr == "EUR" || curr == "USD"
                  ? totalWithCommision?.totalApple
                    ? convertToEur(Number(fixedAmount)) +
                      convertToEur(Number(totalWithCommision?.comissionApple))
                    : convertToEur(Number(fixedAmount))
                  : Number(totalWithCommision?.totalApple || fixedAmount),
              transactionIdPromise: transactionIdPromise,
            },
            (res, genericId) => {
              if (res?.status === 0) {
                const url = `/success?acquiringTransactionId=${
                  res.transactionId
                }&amount=${fixedAmount}&receiverType=${receiverType}&receiverName=${encodeURIComponent(
                  (merchant || data)?.name
                )}&ratingEnabled=${!!(
                  merchant?.reviewRequired || data?.reviewRequired
                )}&tipsEnabled=${!!(
                  merchant?.tipsEnabled || data?.tipsEnabled
                )}&receiverId=${receiverUserId}&paymentProviderEnum=CREDO&paymentMethod=apple&currency=${
                  curr || "GEL"
                }&currencyRate=${
                  curr == "EUR" || curr == "USD" ? currRate : "0"
                }&genericId=${genericId}`;
                navigate(url);
              } else {
                dispatch({ type: SET_LOADING_PAYMENT, payload: false });
                async function failRedirct() {
                  if (integratorCheckOrderId || integratorOrderId) {
                    const { redirectRequired, failRedirectUri } =
                      await postTransactionAction(integratorOrderId);
                    if (redirectRequired && failRedirectUri) {
                      window.location.href = failRedirectUri;
                      return;
                    }
                  }
                }
                failRedirct();
                dispatch({
                  type: SET_PAYMENT_RESULT,
                  payload: {
                    show: true,
                    type: res?.response?.data?.statusCode,
                    group: res?.response?.data?.exceptionGroup,
                  },
                });
              }
            }
          );
        } catch (err) {
          async function failRedirct() {
            if (integratorCheckOrderId || integratorOrderId) {
              const { redirectRequired, failRedirectUri } =
                await postTransactionAction(integratorOrderId);
              if (redirectRequired && failRedirectUri) {
                window.location.href = failRedirectUri;
                return;
              }
            }
          }
          failRedirct();
          dispatch({
            type: SET_PAYMENT_RESULT,
            payload: {
              show: true,
              type: err?.response?.data?.statusCode,
              group: err?.response?.data?.exceptionGroup,
            },
          });
        }
      }
    } else if (paymentType === AVAILABLE_PAYMENT_TYPES.BINANCE_PAY) {
      localStorage.setItem("acquiring", "bog");
      localStorage.setItem("paymentMethod", "card");
      const encryptedData = encrypt.encrypt(
        JSON.stringify({
          amount: Number(fixedAmount),
          toBenefitId: receiverUserId,
          beneficialType: receiverType,
          currency: curr || "GEL",
          ...(totalWithCommision?.comissionCrypto && {
            senderCommissionAmount: totalWithCommision.comissionCrypto,
          }),
          ...(integratorId && { integratorId }),
          ...((integratorOrderId || productId) && {
            integratorOrderId: integratorOrderId || productId,
          }),
          ...(!!description.length && { description }),
          ...(!!formData.payerName.length && { payerName: formData.payerName }),
          ...(!!formData.additionalDescription.length && {
            purpose: formData.additionalDescription,
          }),
          ...(!!formData.payerPersonalNumber.length && {
            personalNumber: formData.payerPersonalNumber,
          }),
          qrType: qrType,
        })
      );
      orderCityPay({
        encryptedData: encryptedData,
        identifier: process.env.REACT_APP_INTEGRATOR_ID,
      })
        .then(({ encryptedData }) => {
          encrypt.setPrivateKey(process.env.REACT_APP_INTEGRATOR_SECRET);
          const { payment_url } = JSON.parse(encrypt.decrypt(encryptedData));
          localStorage.setItem("binance", payment_url);
          window.open(payment_url, "_self");
        })
        .catch(async ({ response: { data: err } }) => {
          if (integratorCheckOrderId || integratorOrderId) {
            const { redirectRequired, failRedirectUri } =
              await postTransactionAction(integratorOrderId);
            if (redirectRequired && failRedirectUri) {
              window.location.href = failRedirectUri;
              return;
            }
          }
          dispatch({
            type: SET_PAYMENT_RESULT,
            payload: {
              show: true,
              type: err?.statusCode,
              group: err?.exceptionGroup,
            },
          });
        })
        .finally(() => {
          dispatch({ type: SET_LOADING_PAYMENT, payload: false });
        });
    } else if (paymentType.includes("ob")) {
      localStorage.setItem("acquiring", "ob");

      const payload = {
        amount: fixedAmount,
        receiverId: receiverUserId,
        receiverType,
        openBankingProviderId: obProviderId,
        qrType: qrType,
        currency: curr || "GEL",
        fromMobile: false,
        ...(totalWithCommision?.comissionOB && {
          senderCommissionAmount: totalWithCommision.comissionOB,
        }),
        ...(integratorId && { integratorId }),
        ...((integratorOrderId || productId) && {
          integratorOrderId:
            selectProducts?.id || integratorOrderId || productId,
        }),
        ...(!!description.length && { description }),
        ...(!!formData.payerName.length && { payerName: formData.payerName }),
        ...(!!formData.additionalDescription.length && {
          purpose: formData.additionalDescription,
        }),
        ...(!!formData.payerPersonalNumber.length && {
          personalNumber: formData.payerPersonalNumber,
        }),
      };
      try {
        const { scaOauthUrl, id, codeVerifier } = await oBRequest(payload);
        window.location.href = scaOauthUrl;
        dispatch({ type: SET_LOADING_PAYMENT, payload: false });
      } catch ({ response: { data: err } }) {
        dispatch({ type: SET_LOADING_PAYMENT, payload: false });
        console.log(err);
        dispatch({
          type: SET_PAYMENT_RESULT,
          payload: {
            show: true,
            type: err?.statusCode,
            group: err?.exceptionGroup,
          },
        });
      }
    } else {
      localStorage.setItem("paymentMethod", "card");

      const payload = {
        amount: fixedAmount,
        receiverId: receiverUserId,
        receiverType,
        qrType:
          merchant?.acquiringDetails?.acquiringDetailsType == "PRODUCT" &&
          qrType == "DEFAULT"
            ? "CUSTOM"
            : qrType,
        ...(totalWithCommision?.comissionStandard && {
          senderCommissionAmount: totalWithCommision.comissionStandard,
        }),
        ...(integratorId && { integratorId }),
        ...((integratorOrderId || productId || selectProducts?.id) && {
          integratorOrderId:
            selectProducts?.id || integratorOrderId || productId,
        }),
        ...(!!description.length && { description }),
        ...(!!formData.payerName.length && { payerName: formData.payerName }),
        ...(!!formData.additionalDescription.length && {
          purpose: formData.additionalDescription,
        }),
        ...(!!formData.payerPersonalNumber.length && {
          personalNumber: formData.payerPersonalNumber,
        }),
        currency: curr || "GEL",
        acquiringType: standardPriorityArray[0]?.acquiringType,
      };

      const iv = generateIV();
      const secretKey = generateKey();
      const ivBase64 = convertBase64(iv);
      const secretKeyBase64 = convertBase64(secretKey);
      const base64 = `${secretKeyBase64}.${ivBase64}`;
      const result = encryptAES(payload, secretKey, iv);

      const data = {
        identifier: process.env.REACT_APP_INTEGRATOR_ID,
        encryptedData: result,
        encryptedKeys: encryptJs(base64, { stringify: false }),
      };

      standardTransaction(data)
        .then(({ uri }) => {
          window.open(uri, "_self");
        })
        .catch(async ({ response: { data: err } }) => {
          console.log(err);
          dispatch({
            type: SET_PAYMENT_RESULT,
            payload: {
              show: true,
              type: err?.statusCode,
              group: err?.exceptionGroup,
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            dispatch({ type: SET_LOADING_PAYMENT, payload: false });
          }, 3000);
        });
    }
  };

  let MERCHANT_ID;
  if (applePriorityArray && applePriorityArray[0]?.acquiringName == "BOG") {
    MERCHANT_ID = MERCHANT_IDENTIFIER;
  } else if (
    applePriorityArray &&
    applePriorityArray[0]?.acquiringName == "TBC"
  ) {
    MERCHANT_ID = MERCHANT_IDENTIFIER_TBC;
  } else {
    MERCHANT_ID = MERCHANT_IDENTIFIER_CREDO;
  }

  // Initialize google pay
  useEffect(() => {
    if (!isAndroid) {
      dispatch({
        type: SET_GPAY_STATE,
        payload: PAYMENT_SERVICE_STATE.NOT_AVAILABLE,
      });
      return;
    }
    const script = document.createElement("script");
    script.src = "https://pay.google.com/gp/p/js/pay.js";
    script.onload = () => {
      // eslint-disable-next-line no-undef
      gpayRef.current = new google.payments.api.PaymentsClient({
        environment: "PRODUCTION", // Change to 'PRODUCTION' for production use
      });
      const isReadyToPayRequest = Object.assign({}, baseRequest);
      isReadyToPayRequest.allowedPaymentMethods = [baseCardPaymentMethod];
      gpayRef.current
        .isReadyToPay(isReadyToPayRequest)
        .then(({ result }) => {
          if (result) {
            dispatch({
              type: SET_GPAY_STATE,
              payload: PAYMENT_SERVICE_STATE.AVAILABLE,
            });
            if (acquiringType?.availableAcquiringTypes?.GOOGLE_PAY) {
              dispatch({
                type: SET_PAYMENT_TYPE,
                payload: AVAILABLE_PAYMENT_TYPES.GOOGLE_PAY,
              });
            }
          } else {
            throw new Error("Gpay not available");
          }
        })
        .catch((err) => {
          dispatch({
            type: SET_GPAY_STATE,
            payload: PAYMENT_SERVICE_STATE.NOT_AVAILABLE,
          });
        });
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [acquiringType]);

  const senderCommissionBool = commisonUsers || commisonMerchant;
  const totalWithCommision = useMemo(() => {
    if (!senderCommissionBool || !amount) {
      return;
    }

    let comissionApple;
    let comissionRoundedApple;
    if (senderCommissionPercentApple?.rateType == "FIXED") {
      comissionApple = null;
      comissionRoundedApple = senderCommissionPercentApple?.senderCommission;
    } else {
      comissionApple =
        (amount * senderCommissionPercentApple?.senderCommission) / 100;
      comissionRoundedApple =
        Math.ceil((comissionApple * 100).toFixed(2)) / 100;
    }

    let comissionGoogle;
    let comissionRoundedGoogle;
    if (senderCommissionPercentGoogle?.rateType == "FIXED") {
      comissionGoogle = null;
      comissionRoundedGoogle = senderCommissionPercentGoogle?.senderCommission;
    } else {
      comissionGoogle =
        (amount * senderCommissionPercentGoogle?.senderCommission) / 100;
      comissionRoundedGoogle =
        Math.ceil((comissionGoogle * 100).toFixed(2)) / 100;
    }
    let comissionStandard;
    let comissionRoundedStandard;
    if (senderCommissionPercentStandard?.rateType == "FIXED") {
      comissionStandard = null;
      comissionRoundedStandard =
        senderCommissionPercentStandard?.senderCommission;
    } else {
      comissionStandard =
        (amount * senderCommissionPercentStandard?.senderCommission) / 100;
      comissionRoundedStandard =
        Math.ceil((comissionStandard * 100).toFixed(2)) / 100;
    }

    let comissionCrypto;
    let comissionRoundedCrypto;
    if (senderCommissionPercentCrypto?.rateType == "FIXED") {
      comissionCrypto = null;
      comissionRoundedCrypto = senderCommissionPercentCrypto?.senderCommission;
    } else {
      comissionCrypto =
        (amount * senderCommissionPercentCrypto?.senderCommission) / 100;
      comissionRoundedCrypto =
        Math.ceil((comissionCrypto * 100).toFixed(2)) / 100;
    }

    let comissionOB;
    let comissionRoundedOB;
    if (senderCommissionPercentOB?.rateType == "FIXED") {
      comissionOB = null;
      comissionRoundedOB = senderCommissionPercentOB?.senderCommission;
    } else {
      comissionOB =
        (amount * senderCommissionPercentOB?.senderCommission) / 100;
      comissionRoundedOB = Math.ceil((comissionOB * 100).toFixed(2)) / 100;
    }
    return {
      totalApple: (Number(amount) + Number(comissionRoundedApple)).toFixed(2),
      comissionApple: parseFloat(comissionRoundedApple),
      totalGoogle: (Number(amount) + Number(comissionRoundedGoogle)).toFixed(2),
      comissionGoogle: parseFloat(comissionRoundedGoogle),
      totalStandard: (
        Number(amount) + Number(comissionRoundedStandard)
      ).toFixed(2),
      comissionStandard: parseFloat(comissionRoundedStandard),
      totalCrypto: (Number(amount) + Number(comissionRoundedCrypto)).toFixed(2),
      comissionCrypto: parseFloat(comissionRoundedCrypto),
      totalOB: (Number(amount) + Number(comissionRoundedOB)).toFixed(2),
      comissionOB: parseFloat(comissionRoundedOB),
    };
  }, [
    amount,
    senderCommissionPercentApple,
    senderCommissionPercentCrypto,
    senderCommissionPercentGoogle,
    senderCommissionPercentStandard,
    senderCommissionBool,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const {
          data: { value: data },
        } = await paymentServiceInstance.get(
          `/api/v1/acquiring/choice/external?receiverId=${encodeURI(
            receiverId
          )}&receiverType=${encodeURI(receiverType)}`
        );

        setAcquiringType(data);

        return data;
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  // Initialize apple pay
  useEffect(() => {
    if (window.ApplePaySession) {
      const promise =
        window.ApplePaySession.canMakePaymentsWithActiveCard(MERCHANT_ID);
      promise
        .then((canMakePayments) => {
          if (canMakePayments) {
            if (acquiringType?.availableAcquiringTypes?.APPLE_PAY) {
              dispatch({
                type: SET_PAYMENT_TYPE,
                payload: AVAILABLE_PAYMENT_TYPES.APPLE_PAY,
              });
            }

            dispatch({
              type: SET_APPLE_PAY_STATE,
              payload: PAYMENT_SERVICE_STATE.AVAILABLE,
            });
          } else {
            throw new Error("can't make payments");
          }
        })
        .catch(() => {
          dispatch({
            type: SET_APPLE_PAY_STATE,
            payload: PAYMENT_SERVICE_STATE.NOT_AVAILABLE,
          });
        });
    } else {
      dispatch({
        type: SET_APPLE_PAY_STATE,
        payload: PAYMENT_SERVICE_STATE.NOT_AVAILABLE,
      });
    }
  }, [acquiringType, MERCHANT_ID]);

  useEffect(() => {
    if (
      applePayState !== PAYMENT_SERVICE_STATE.LOADING &&
      gpayState !== PAYMENT_SERVICE_STATE.LOADING &&
      ((status && status.toString() === "FAIL") ||
        transactionStatus === "FAILED")
    ) {
      dispatch({
        type: SET_PAYMENT_RESULT,
        payload: {
          show: true,
          type: "ERROR",
        },
      });
    }
  }, [status, applePayState, transactionStatus, gpayState]);

  useEffect(() => {
    let amountSet =
      requestedAmount ||
      requestedAmount1 ||
      data?.amountForDefaultQR ||
      merchant?.amountForDefaultQR;
    if (amountSet && !isNaN(amountSet)) {
      dispatch({ type: SET_AMOUNT, payload: amountSet });
    }
  }, [
    dispatch,
    requestedAmount,
    requestedAmount1,
    merchant?.amountForDefaultQR,
    data?.amountForDefaultQR,
  ]);

  useEffect(() => {
    if (paymentType === "card" && amount) {
      if (senderCommissionPercentStandard?.senderCommission == 0) {
        setCommisionAmount(0);
      } else {
        setCommisionAmount(totalWithCommision?.totalStandard);
      }
    } else if (paymentType === "gpay" && amount) {
      if (senderCommissionPercentGoogle?.senderCommission == 0) {
        setCommisionAmount(0);
      } else {
        setCommisionAmount(totalWithCommision?.totalGoogle);
      }
    } else if (paymentType === "apple" && amount) {
      if (senderCommissionPercentApple?.senderCommission == 0) {
        setCommisionAmount(0);
      } else {
        setCommisionAmount(totalWithCommision?.totalApple);
      }
    } else if (paymentType === "binance" && amount) {
      if (senderCommissionPercentCrypto?.senderCommission == 0) {
        setCommisionAmount(0);
      } else {
        setCommisionAmount(totalWithCommision?.totalCrypto);
      }
    } else if (paymentType.includes("ob") && amount) {
      if (senderCommissionPercentOB?.senderCommission == 0) {
        setCommisionAmount(0);
      } else {
        setCommisionAmount(totalWithCommision?.totalOB);
      }
    }
    if (paymentType && amount) {
      dispatch({
        type: SET_INPUT_ERROR,
        payload:
          Number(amount) < Number(minValDB) ||
          Number(amount) > Number(maxValDB),
      });
    }
  }, [amount, paymentType, totalWithCommision, minValDB, maxValDB]);

  console.log(merchant || data, "informacia mimgebis");

  useEffect(() => {
    if (
      !acquiringType?.availableAcquiringTypes?.APPLE_PAY &&
      !acquiringType?.availableAcquiringTypes?.GOOGLE_PAY &&
      !acquiringType?.availableAcquiringTypes?.STANDARD &&
      !acquiringType?.availableAcquiringTypes?.OPEN_BANKING &&
      acquiringType?.availableAcquiringTypes?.CRYPTO
    ) {
      changePaymentType("binance");
    }
  }, [acquiringType]);

  useEffect(() => {
    if (qrType == "REQUEST") {
      async function getOrderStatus() {
        try {
          const data = await checkOrderStatus(
            integratorCheckOrderId,
            integratorId
          );
        } catch ({ response: { data: error } }) {
          console.log(error, "check order status error");
          setReqQrStatus(error?.statusCode);
        }
      }
      getOrderStatus();
    }
  }, []);

  useEffect(() => {
    async function getProviders() {
      const data = await getOBProviders();
      setObProvider(data);
      if (
        !acquiringType?.availableAcquiringTypes?.APPLE_PAY &&
        !acquiringType?.availableAcquiringTypes?.GOOGLE_PAY &&
        !acquiringType?.availableAcquiringTypes?.STANDARD &&
        acquiringType?.availableAcquiringTypes?.OPEN_BANKING &&
        !acquiringType?.availableAcquiringTypes?.CRYPTO
      ) {
        if (data[0]?.name == "თიბისი") {
          dispatch({ type: SET_PAYMENT_TYPE, payload: "ob_tbc" });
          setObProviderId(data[0]?.id);
        } else if (data[0]?.name == "საქართველო") {
          dispatch({ type: SET_PAYMENT_TYPE, payload: "ob_bog" });
          setObProviderId(data[0]?.id);
        } else if (data[0]?.name == "კრედო") {
          dispatch({ type: SET_PAYMENT_TYPE, payload: "ob_credo" });
          setObProviderId(data[0]?.id);
        } else if (data[0]?.name == "ლიბერთი") {
          dispatch({ type: SET_PAYMENT_TYPE, payload: "ob_liberty" });
          setObProviderId(data[0]?.id);
        }
      }
    }
    getProviders();
  }, [acquiringType]);

  useEffect(() => {
    if (isSafari || isChrome || isIOS) {
      console.log("Safari or Chrome");
    } else {
      setOpenChromeModal(true);
    }
  }, []);

  if (integratorCheckOrderId || integratorOrderId) {
    localStorage.setItem(
      "integratorOrderId",
      integratorCheckOrderId || integratorOrderId
    );
  }

  if (
    applePayState === PAYMENT_SERVICE_STATE.LOADING ||
    gpayState === PAYMENT_SERVICE_STATE.LOADING
  ) {
    return <Loader />;
  }

  if (userError?.statusCode === 2189 || merchantError?.statusCode === 2158) {
    return <UserNotFound />;
  }

  if (systemData?.systemStatus === "PASSIVE") {
    return <Navigate to="/downtime" />;
  }

  if (transactionStatus === "SUCCESS" || transactionStatus === "REFUND") {
    const redirectURL = `/success?receiverId=${receiverUserId}&acquiringTransactionId=${transactionId}&amount=${fixedAmount}&receiverName=${encodeURIComponent(
      (merchant || data)?.name
    )}&ratingEnabled=${!!(receiverType === "BRANCH")}`;
    return <Navigate to={redirectURL} />;
  }

  const orderContent = (
    <div className="w-[100%] gap-[17px] text-center flex flex-col justify-center items-center mt-[50px]">
      <ExclamIcon status={reqQrStatus} />
      <p>
        {reqQrStatus == 6016
          ? t("description.orderPaid")
          : t("description.noValid")}
      </p>
    </div>
  );

  const orderNotFound = qrCheckError;

  return (
    <>
      <main className="max-w-[700px] mx-auto h-full flex flex-col justify-between">
        {openChromModal && (
          <EcommerceModal
            Icon={GoogleChromeIcon}
            subDesc={
              <>
                For best experience, open in Chrome. <br />
                საუკეთესო გამოცდილებისთვის გახსენით Chrome-ში.
              </>
            }
            onClose={() => {
              window.location.href = `intent://${window.location.href.replace(
                /^https?:\/\//,
                ""
              )}#Intent;scheme=https;package=com.android.chrome;end`;
            }}
            isChromeModal={true}
          />
        )}
        {selectProducts?.show ? (
          <ProductList
            products={(merchant || data)?.acquiringDetails?.productList}
            dispatch={dispatch}
            productId={selectProducts?.id}
          />
        ) : (
          <>
            <div style={{ justifySelf: "flex-start" }}>
              <TippingUser user={data || merchant} />
              <div className="h-[40px] rounded-t-[32px] bg-white mt-[-40px]"></div>
              {reqQrStatus ? (
                orderContent
              ) : (
                <div className="flex flex-col w-full px-[25px] items-center  ">
                  <form
                    className="w-full text-center flex flex-col justify-between"
                    onSubmit={(e) => e.preventDefault()}
                    autoComplete="off"
                  >
                    {orderNotFound ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <img src={fail} style={{ width: "50px" }} alt="fail" />
                        <p
                          style={{
                            color: "#3F2C65",
                            fontSize: "16px",
                            marginTop: "19px",
                            whiteSpace: "pre-line",
                          }}
                        >
                          {t("description.qrCheckError")}
                        </p>
                      </div>
                    ) : (
                      <div className="relative">
                        {qrType === QR_TYPE.CHECK ||
                        qrType === QR_TYPE.REQUEST ? (
                          <p className="text-sm text-gray-500">
                            {t("requestedPayment")}
                          </p>
                        ) : (
                          <p className="text-sm text-black-400">
                            {merchant?.acquiringDetails?.acquiringDetailsType ==
                              "PRODUCT" && qrType == "DEFAULT"
                              ? "amount to pay"
                              : t("enterAmount")}
                          </p>
                        )}
                        <div className="flex align-center justify-center">
                          <div className="flex items-center relative">
                            <span
                              className={`${
                                amount.length
                                  ? "text-blue-500"
                                  : "text-blue-100"
                              } text-[36px] absolute bottom-1 left-[-25px]`}
                            >
                              {curr == "EUR" && "€"}
                              {curr == "GEL" && "₾"}
                              {curr == "USD" && "$"}
                            </span>
                          </div>
                          <div className="mt-1 relative after:content-[''] after:absolute after:bottom-0 after:left-[50%] after:translate-x-[-50%] after:w-[90px] after:h-[1px] after:bg-blue-100">
                            <input
                              style={{
                                width:
                                  Math.max(
                                    amount ? String(amount).length : 4,
                                    2
                                  ) + "ch",
                              }}
                              size="1"
                              className="main-input min-w-0 text-[48px] outline-none text-center   disabled:bg-transparent "
                              id="amount"
                              type="tel"
                              disabled={isAmountInputDisabled}
                              inputMode="decimal"
                              autoFocus
                              autoComplete="off"
                              value={amount}
                              onChange={handleInputChange}
                              placeholder="0.00"
                            />
                          </div>
                        </div>
                        {!inputError && commisonAmount ? (
                          <p
                            className="w-full mt-3 absolute bottom-[-2.0rem] left-[50%] translate-x-[-50%]"
                            style={{ color: "#6C63FF" }}
                          >
                            {t("description.totalPayable", {
                              total: commisonAmount,
                              ...(curr == "EUR" && { currency: "€" }),
                              ...(curr == "USD" && { currency: "$" }),
                              ...(curr == "GEL" && { currency: "₾" }),
                            })}
                          </p>
                        ) : null}
                        {inputError ? (
                          <p className="text-red w-full mt-3 absolute bottom-[-2.5rem] left-[50%] translate-x-[-50%]">
                            {t("description.enterAmount", {
                              minValDB,
                              maxValDB,
                              ...(curr == "EUR" && { currency: "€" }),
                              ...(curr == "USD" && { currency: "$" }),
                              ...(curr == "GEL" && { currency: "₾" }),
                            })}
                          </p>
                        ) : null}
                      </div>
                    )}
                    {(data || merchant)?.descriptionRequired &&
                      !orderNotFound && (
                        <CustomInput
                          placeholder={t("inputs.description")}
                          ref={descriptionRef}
                          onChange={(e) => {
                            setDescription(e.value);
                          }}
                          value={description}
                          backgroundColor="#FAFAFA"
                          width="100%"
                          margin="47px 0 0 0"
                          borderRadius="16px"
                          descError={descError}
                        />
                      )}
                    <div className="flex flex-col gap-[20px] mt-[37.25px]">
                      {merchant?.payerNameRequired && !orderNotFound && (
                        <CustomInput
                          name="payerName"
                          placeholder={t("inputs.payerName")}
                          ref={payerNameRef}
                          onChange={(e) => {
                            const { name, value } = e;
                            setFormData((prevState) => ({
                              ...prevState,
                              [name]: value.replace(
                                /^[^a-zA-Z\p{L}]+|[^\p{L}\s]/gu,
                                ""
                              ),
                            }));
                          }}
                          value={formData.payerName}
                          backgroundColor="#FAFAFA"
                          width="100%"
                          margin="0 0 0 0"
                          borderRadius="16px"
                        />
                      )}
                      {(merchant?.personalNumberRequired ||
                        merchant?.personalNumberOrPassportRequired) &&
                        !orderNotFound && (
                          <CustomInput
                            name="payerPersonalNumber"
                            placeholder={
                              merchant?.personalNumberRequired
                                ? t("inputs.payerPersonalNumber")
                                : t("inputs.personalOrPassport")
                            }
                            ref={payerPersonalNumberRef}
                            onChange={(e) => {
                              const { name, value } = e;
                              setFormData((prevState) => ({
                                ...prevState,
                                [name]:
                                  merchant?.personalNumberRequired &&
                                  !isNonResident
                                    ? value.replace(/[^0-9]/g, "")
                                    : value,
                              }));
                            }}
                            value={formData.payerPersonalNumber}
                            backgroundColor="#FAFAFA"
                            width="100%"
                            margin="0 0 0 0"
                            borderRadius="16px"
                            // type={"number"}
                            error={personalNumberError}
                          />
                        )}
                      {(merchant || data)?.acquiringDetails
                        ?.acquiringDetailsType == "PRODUCT" &&
                        qrType == "DEFAULT" && (
                          <ProductSelect
                            name={selectProducts?.name}
                            onClick={() =>
                              dispatch({
                                type: SET_PRODUCTS,
                                payload: {
                                  show: true,
                                  id: selectProducts?.id || null,
                                },
                              })
                            }
                          />
                        )}
                      {merchant?.personalNumberRequired && (
                        <div className="flex justify-start items-center gap-[7px]">
                          <input
                            id="checkBox"
                            type="checkbox"
                            checked={isNonResident}
                            onChange={(event) => {
                              setIsNonResident(event.target.checked);
                              setFormData((prevState) => ({
                                ...prevState,
                                payerPersonalNumber: "",
                              }));
                            }}
                            className=" w-[18px] h-[18px]  bg-white accent-[#6C63FF] cursor-pointer "
                          />
                          <label
                            htmlFor="checkBox"
                            className="text-[#454545] text-[14px]"
                          >
                            {t("inputs.noneResident")}
                          </label>
                        </div>
                      )}

                      {merchant?.purposeRequired && !orderNotFound && (
                        <CustomInput
                          name="additionalDescription"
                          placeholder={t("inputs.additionaDescription")}
                          ref={additionalDescriptionRef}
                          onChange={(e) => {
                            const { name, value } = e;
                            setFormData((prevState) => ({
                              ...prevState,
                              [name]: value,
                            }));
                          }}
                          value={formData.additionalDescription}
                          backgroundColor="#FAFAFA"
                          width="100%"
                          margin="0 0 0 0"
                          borderRadius="16px"
                        />
                      )}
                    </div>

                    <div
                      className={`mt-${
                        (data || merchant)?.descriptionRequired ? "5" : "[60px]"
                      }`}
                    >
                      <Select
                        style={{
                          borderRadius: "13px",
                          display: orderNotFound && "none",
                        }}
                        onClick={() =>
                          dispatch({ type: SET_TOGGLE_PAYMENT_MODAL })
                        }
                        type={paymentType}
                      />

                      <div>
                        <button
                          className="inline-flex justify-center gap-2 w-full bg-[#6C63FF] p-4 text-[20px] text-white mt-4 rounded-2xl disabled:bg-[#6C63FF60] text-center items-center"
                          style={{
                            height: "50px",
                            borderRadius: "13px",
                            ...(orderNotFound && {
                              backgroundColor: "rgba(63, 44, 101, 0.2)",
                              color: "black",
                            }),
                          }}
                          type="submit"
                          disabled={
                            !orderNotFound &&
                            (!Number(amount) || inputError || loadingPayment)
                          }
                          onClick={handlePayment}
                        >
                          {orderNotFound ? t("retry") : t("description.pay")}
                          {loadingPayment ? <Spinner /> : null}
                        </button>
                      </div>
                    </div>

                    <div className="mt-[15px] text-[11px]">
                      <p className="text-gray-100">
                        {t("description.agreetc")}
                      </p>
                      <Link
                        className="text-black-200"
                        to="/terms-and-conditions"
                      >
                        {t("description.termsAndCon")}
                      </Link>
                    </div>
                  </form>
                  <form ref={formRef} method="post" action="">
                    <input type="hidden" name="creq" ref={inputRef} value="" />
                    <noscript>
                      <center>
                        <br />
                        Please click the submit button below.
                        <br />
                        <input type="submit" value="Submit" />
                      </center>
                    </noscript>
                  </form>
                </div>
              )}
            </div>
            <div className="flex justify-center items-center min-h-[200px] h-2/3">
              <PoweredByKeepz />
            </div>
          </>
        )}
      </main>
      <Modal
        show={showPaymentModal}
        position="bottom"
        onClose={() => dispatch({ type: SET_TOGGLE_PAYMENT_MODAL })}
      >
        <SelectPaymentMethod
          dispatch={dispatch}
          paymentType={paymentType}
          changePaymentType={changePaymentType}
          availablePaymentOptions={availablePaymentOptions}
          handleClose={() => dispatch({ type: SET_TOGGLE_PAYMENT_MODAL })}
          obProviders={ObProvider}
          setObProviderId={setObProviderId}

          // checkRanges={checkRanges}
        />
      </Modal>
      <Modal
        show={showInstallmentModal}
        position="bottom"
        onClose={() => dispatch({ type: SET_TOGGLE_INSTALLMENT_MODAL })}
      >
        <InstallmentModal
          installmentProperties={installmentProperties}
          language={language}
          payload={{
            amount: fixedAmount,
            receiverId: receiverUserId,
            receiverType,
            qrType:
              merchant?.acquiringDetails?.acquiringDetailsType == "PRODUCT" &&
              qrType == "DEFAULT"
                ? "CUSTOM"
                : qrType,
            ...(totalWithCommision?.comissionStandard && {
              senderCommissionAmount: totalWithCommision.comissionStandard,
            }),
            ...(integratorId && { integratorId }),
            ...((integratorOrderId || productId || selectProducts?.id) && {
              integratorOrderId:
                selectProducts?.id || integratorOrderId || productId,
            }),
            ...(!!description.length && { description }),
            ...(!!formData.payerName.length && {
              payerName: formData.payerName,
            }),
            ...(!!formData.additionalDescription.length && {
              purpose: formData.additionalDescription,
            }),
            ...(!!formData.payerPersonalNumber.length && {
              personalNumber: formData.payerPersonalNumber,
            }),
            currency: curr || "GEL",
            acquiringType: "BOG_STANDARD",
          }}
        />
      </Modal>
      {paymentResult?.show && !orderNotFound ? (
        <div
          className=" flex justify-center    fixed  overflow-hidden top-0 w-[100vw] h-[100vh] bg-black-400 opacity-9 z-40"
          onClick={(e) => {
            if (e.currentTarget !== e.target) {
              return;
            }
            handlePaymentResulClose(paymentResult?.type);
          }}
        >
          <PaymentFeedback
            className={true}
            type={paymentResult?.type}
            action={() => handlePaymentResulClose(paymentResult?.type)}
            group={paymentResult?.group}
          />
        </div>
      ) : null}
    </>
  );
};

export default BogPayment;
