export const SET_GPAY_STATE = "SET_GPAY_STATE";

export const SET_APPLE_PAY_STATE = "SET_APPLE_PAY_STATE";

export const SET_LOADING_PAYMENT = "SET_LOADING_PAYMENT";

export const SET_PAYMENT_RESULT = "SET_PAYMENT_RESULT";

export const SET_AMOUNT = "SET_AMOUNT";

export const SET_INPUT_ERROR = "SET_INPUT_ERROR";

export const SET_TOGGLE_PAYMENT_MODAL = "SET_TOGGLE_PAYMENT_MODAL";

export const ADD_AVAILABLE_PAYMENT_OPTION = "ADD_AVAILABLE_PAYMENT_OPTION";

export const SET_PAYMENT_TYPE = "SET_PAYMENT_TYPE";

export const SET_PRODUCTS = "SET_PRODUCTS";

export const SET_TOGGLE_INSTALLMENT_MODAL = "SET_TOGGLE_INSTALLMENT_MODAL";
