import { SET_AMOUNT, SET_PRODUCTS } from "features/bog/reducer/actions";

const ProductItem = ({
  name = "Product item",
  id,
  amount,
  currency,
  currencyRate,
  dispatch,
  setSelectedProduct,
  slelectedProduct = "",
  productId,
}) => {
  console.log(productId, "productID");
  return (
    <div
      className={`rounded-[16px] border-[1px] py-[10px] px-[15px] h-[60px] ${
        slelectedProduct == id || productId == id
          ? "border-[#6C63FF] bg-[#6C63FF1A]"
          : "bg-[#FAFAFA] border-[#FAFAFA]"
      }   mt-[10px]`}
      onClick={() => {
        setSelectedProduct(id);
        setTimeout(() => {
          dispatch({
            type: SET_PRODUCTS,
            payload: {
              show: false,
              id: id,
              currency: currency,
              currencyRate: currencyRate,
              name: name,
            },
          });
          dispatch({ type: SET_AMOUNT, payload: amount });
        }, 1000);
      }}
    >
      <p className="text-[16px] mt-[10px] text-[#000000]">{name}</p>
    </div>
  );
};

export default ProductItem;
