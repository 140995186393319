import { acceptApplePayCredo } from "api";
import axios from "axios";
import { JSEncrypt } from "jsencrypt";

const xhrRequest = (type, url, resolve, reject) => {
  let xhr = new XMLHttpRequest();

  xhr.onload = function () {
    if (xhr.status >= 200 && xhr.status < 300) {
      resolve(JSON.parse(xhr.response));
    } else {
      reject({
        status: xhr.status,
        statusText: xhr.statusText,
      });
    }
  };
  xhr.onerror = function () {
    reject({
      status: xhr.status,
      statusText: xhr.statusText,
    });
  };

  xhr.open(type, url);
  xhr.setRequestHeader("Content-Type", "application/json");

  return xhr;
};

const getApplePaySession = (url) => {
  return new Promise((resolve, reject) => {
    const payload = {
      data: {
        type: "applepay_session",
        attributes: {
          url: url,
        },
      },
    };

    xhrRequest(
      "POST",
      "https://keepzpaycredo.ufc.ge:443/applePay/validateMerchant",
      resolve,
      reject
    ).send(JSON.stringify(payload));
  });
};

const startApplePaySessionUfc = (
  { transactionIdPromise, amount },
  callback = null
) => {
  const request = {
    countryCode: "GE",
    currencyCode: "GEL",
    supportedNetworks: ["visa", "masterCard"],
    merchantCapabilities: ["supports3DS"],
    total: { label: "Keepz", amount: amount.toString() },
  };
  let genericId = "";
  let merchantTransactionId = "";
  let startPaymentError = undefined;
  const session = new window.ApplePaySession(10, request);
  session.onvalidatemerchant = async function (event) {
    const { validationURL } = event;

    transactionIdPromise
      .then((data) => {
        console.log(data, "apple pay data");
        merchantTransactionId = data.transactionId;
        genericId = data.genericId;
        getApplePaySession(validationURL)
          .then((response) => {
            console.log(response);
            session.completeMerchantValidation(
              JSON.parse(response.data.attributes.session)
            );
          })
          .catch((err) => {
            console.log(err);
          });
      })

      .catch((err) => {
        startPaymentError = err;
        console.log(err, "startApplePaySession");
      });
  };

  session.onpaymentmethodselected = (event) => {
    try {
      const update = {
        newTotal: { label: "Keepz", amount },
      };

      session.completePaymentMethodSelection(update);
    } catch (error) {
      console.log(error, "onpaymentMethodSelected");
    }
  };

  session.onshippingmethodselected = (event) => {
    try {
      console.log("onShippingmethodSelected");
      const update = {};
      session.completeShippingMethodSelection(update);
    } catch (error) {
      console.log(error, "onShippingmethodSelected");
    }
  };

  session.onpaymentauthorized = async (event) => {
    const { payment } = event;
    const payload = {
      data: {
        type: "payment",
        attributes: {
          apple_pay: {
            token: payment.token,
            shipping_contact: payment.shippingContact,
            application_data: merchantTransactionId,
            payment_request: request,
          },
        },
      },
    };

    const acceptPayment = acceptApplePayCredo(payload);
    let status = {
      status: startPaymentError || window.ApplePaySession.STATUS_FAILURE,
    };

    acceptPayment.then(({ data }) => {
      console.log(data, "რეჯისტერის დატა");

      console.log(merchantTransactionId, "მერჩანტის ტრანზაქციის აიდი");
      if (data?.attributes?.status == "FINISHED") {
        status = window.ApplePaySession.STATUS_SUCCESS;
      }
      const result = {
        status,
        transactionId: merchantTransactionId,
      };

      session.completePayment(result);

      if (callback) {
        callback(result, genericId);
      }
    });
  };

  session.oncancel = function (event) {
    const result = {
      status: startPaymentError || window.ApplePaySession.STATUS_FAILURE,
    };

    if (callback) {
      callback(result, genericId);
    }
  };
  session.begin();
};

export default startApplePaySessionUfc;
