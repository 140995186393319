const ProductSelect = ({ onClick, name, productId }) => {
  return (
    <div
      onClick={onClick}
      className="flex justify-between bg-[#FAFAFA] items-center rounded-[16px] py-[20px] px-[20px]"
    >
      <p className="text-[16px] text-[#000000] opacity-50 ">
        {name || "Product"}
      </p>
      <svg
        width="9"
        height="14"
        viewBox="0 0 9 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.01083 13.0204C2.1486 13.0219 2.28528 12.9975 2.41261 12.9485C2.53994 12.8995 2.6553 12.8271 2.75172 12.7355L8.61066 7.41066C8.73047 7.30954 8.82685 7.18674 8.89391 7.04976C8.96097 6.91278 8.99729 6.76451 9.00066 6.61402C9.00178 6.46283 8.96747 6.31323 8.90011 6.17565C8.83276 6.03806 8.73398 5.9158 8.61066 5.81738L2.75172 0.486339C2.65475 0.395507 2.53926 0.323607 2.41204 0.274853C2.28482 0.226099 2.14842 0.201473 2.01083 0.202419C1.87076 0.201459 1.73188 0.226305 1.60217 0.275527C1.47247 0.324748 1.35451 0.397371 1.2551 0.489211C1.15569 0.581051 1.0768 0.690292 1.02296 0.810639C0.969119 0.930986 0.941402 1.06006 0.941406 1.19042C0.942632 1.32639 0.972902 1.46078 1.03046 1.58576C1.08801 1.71074 1.1717 1.82382 1.27665 1.91842L6.47181 6.61506L1.27777 11.3065C1.17282 11.4011 1.08913 11.5142 1.03157 11.6392C0.97402 11.7641 0.943749 11.8985 0.942524 12.0345C0.942815 12.1646 0.970712 12.2934 1.02461 12.4134C1.07851 12.5334 1.15736 12.6424 1.25662 12.734C1.35588 12.8256 1.4736 12.8981 1.60303 12.9472C1.73246 12.9964 1.87105 13.0212 2.01083 13.0204Z"
          fill="#3C3C43"
          fill-opacity="0.3"
        />
      </svg>
    </div>
  );
};

export default ProductSelect;
