import { credoInstallment } from "api";
import CustomInput from "components/CustomInput";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  convertBase64,
  encrypt,
  encryptAES,
  generateIV,
  generateKey,
} from "utils/encryption";

function InstallmentModal({ installmentProperties, language, payload }) {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});
  const [personalNumErr, setPersonalNumberError] = useState(false);
  const inputRefs = useRef({});
  const { en, ka } = installmentProperties;
  const enArray = Object.entries(en);
  const kaArray = Object.entries(ka);
  const arr = language == "en" ? enArray : kaArray;

  const validateAndFocus = () => {
    let hasError = false;
    arr.reverse().forEach(([_, propertyName]) => {
      if (!formData[propertyName]) {
        if (inputRefs.current[propertyName]) {
          inputRefs.current[propertyName].current.focus();
          hasError = true;
        }
      }
      if (
        propertyName == "personalNumber" &&
        formData.personalNumber?.length != 11
      ) {
        setPersonalNumberError(true);
        hasError = true;
      } else if (
        propertyName == "personalNumber" &&
        formData.personalNumber?.length == 11
      ) {
        hasError = false;
        setPersonalNumberError(false);
      }
    });
    return hasError;
  };

  const trigggerInstallment = async () => {
    const parameters = {
      ...payload,
      ...formData,
    };
    console.log(parameters, "parametrebi");
    const iv = generateIV();
    const secretKey = generateKey();
    const ivBase64 = convertBase64(iv);
    const secretKeyBase64 = convertBase64(secretKey);
    const base64 = `${secretKeyBase64}.${ivBase64}`;
    const result = encryptAES(parameters, secretKey, iv);

    const data = {
      identifier: process.env.REACT_APP_INTEGRATOR_ID,
      encryptedData: result,
      encryptedKeys: encrypt(base64, { stringify: false }),
    };

    const promise = await credoInstallment(data);
    console.log(promise, "what have been returned from promise");
  };

  return (
    <>
      <div className="flex flex-col w-full bg-white p-[10px] rounded-t-2xl">
        <h2 className="text-[18px] font-bold">{t("installmentText")}</h2>
        <div className="mt-[20px]">
          {arr.map(([label, propertyName]) => {
            if (!inputRefs.current[propertyName]) {
              inputRefs.current[propertyName] = React.createRef();
            }

            return (
              <CustomInput
                placeholder={label}
                name={propertyName}
                onChange={(e) => {
                  const { name, value } = e;
                  setFormData((prevState) => ({
                    ...prevState,
                    [propertyName]: value,
                  }));
                }}
                value={formData[propertyName]}
                backgroundColor="#FAFAFA"
                width="100%"
                margin="5px 0 0 0"
                borderRadius="16px"
                ref={inputRefs.current[propertyName]}
                error={propertyName == "personalNumber" && personalNumErr}
              />
            );
          })}
        </div>

        <button
          className="p-[10px] bg-[#6C63FF] text-white w-full rounded-[13px] mt-[20px]"
          onClick={() => {
            const hasError = validateAndFocus();
            if (hasError) return;
            trigggerInstallment();
          }}
        >
          Continue
        </button>
      </div>
    </>
  );
}

export default InstallmentModal;
